@font-face {
  font-family: 'THSarabun';
  src: url('../../public/fonts/THSarabunNew.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabun';
  src: url('../../public/fonts/THSarabunNew-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.print-doc {
  font-family: 'THSarabun', 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  /* font-weight: 100;
  font-size: 14px;
  line-height: 30px;  
  background: #4CAF50; */
  width: 21cm;
  height: 29cm;
  margin-right: 15px;
  margin-top: 10px;
}

/* .print-doc2 {
  font-family: 'THSarabun', 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 15px;
  /* font-weight: 100;
  font-size: 14px;
  line-height: 30px;  
  background: #4CAF50; 
  width: 21cm;
  height: 29cm;
  margin-top: 40px;
} */

.print-doc2 {
  font-family: 'THSarabun', 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 15px;
  width: 21cm;
  height: 29cm;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  padding-left: 35px; /* Add padding as needed */
  padding-right: 20px;
  box-sizing: border-box; /* Ensure padding doesn't affect overall width */
  overflow: auto; /* Allow content to overflow and scroll when necessary */
}

.print-doc {
  width: 100%;
  /* Add any additional styling needed for the content */
}

.photo-1dot5 {
  width: 132px; /* Approximately 3.5cm in pixels (3.5 * 37.8) */
  height: 159.6px; /* Approximately 4.2cm in pixels (4.2 * 37.8) */
  border: 1px solid black; /* Border for visualization */
}

.photo-bt-label {
  width: 152px;
  height: 239.6px;
  border: 1px solid black;
}

.box-space {
  border: 1px solid black;
  padding-left: 9px;
  padding-right: 9px;
  margin: 1px;
}

.box-closed {
  border: 1px solid black;
  padding-left: 9px;
  padding-right: 9px;
}

.text-left {
  text-align: left; /* Align content to the left */
}

.a4-layout {
  width: 21cm; /* Or specify a fixed width in pixels */
  max-width: none; /* Disable responsive behavior */
  height: 29.7cm;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  white-space: nowrap; /* Prevent text wrapping */
}

.center-scroll-x {
  background-color: #FFFFFF;
  padding: 25px;
  margin-top: 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  overflow-x: auto;
  display: flex;
  justify-content: center;
  
}

@media (max-width: 1138px) {
  .center-scroll-x {
    justify-content: flex-start; /* Align items to the left when window width is <= 768px */
    align-items: flex-start;
  }
}

.underline {
  position: relative;
  display: inline-block;
  white-space: nowrap; /* Prevent text wrapping */
  margin: 0; /* Remove default margin */
}

.underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 7px; /* adjust as needed */
  width: 100%;
  border-bottom: 1px solid gray; /* adjust thickness and color */
  opacity: 0.33; /* adjust opacity as needed */
}