.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent black background */
  z-index: 998; /* Ensure it's behind the spinner but above other content */
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
  z-index: 999; /* Ensure it's on top of other content */
}

.spinner {
  width: 80px;
  height: 80px;
  margin: 100px auto;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
