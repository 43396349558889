@font-face {
  font-family: 'Kanit';
  src: url('../public/fonts/Kanit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url('../public/fonts/Kanit-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Kanit', 'Roboto', Helvetica, Arial, sans-serif;
  background: #4CAF50;
  /* font-weight: 100;
  font-size: 14px;
  line-height: 30px;  
  background: #4CAF50; */
}

.box-content {
  background-color: #FFFFFF;
  padding: 25px;
  margin-top: 1rem;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.active {
  background-color: gray;
}

/* custom margin for set main content margin-left */
.content-spacing {
  margin-left: 14rem;
  margin-top: 3rem;
  padding: 2rem;
}