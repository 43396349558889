@font-face {
  font-family: 'THSarabun';
  src: url('../../public/fonts/THSarabunNew.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabun';
  src: url('../../public/fonts/THSarabunNew-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.print-report {
  font-family: 'THSarabun', 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 17px;
  /* font-weight: 100;
  font-size: 14px;
  line-height: 30px;  
  background: #4CAF50; 
  width: 21cm;
  height: 29cm;
  margin-right: 15px;
  margin-top: 20px; 
  overflow-y: auto;*/
}

